<template>
  <div :id="tableConfig.refName+'1'" :class="tableConfig.pager.PageStatus==1?'tablePagerClass':'tableClass'">
      <el-table :ref="tableConfig.refName" :data="tableData" :max-height="maxHeight" fit size="mini"  height="100%"  border style="width: 100%" stripe tooltip-effect="light" :header-cell-style="{'text-align':'center'}"
　　　　　　@selection-change="handleSelectionChange" highlight-current-row>
          <el-table-column type="selection" v-if="tableConfig.checkType==='Checkbox'" width="55" align="center" :fixed="true">
          </el-table-column>
          <template v-for="(item, index) in columnsData" >                
              <el-table-column v-if="item.IsShow != 'N'"
                  :key="index"
                  :sortable="item.IsSortable==='Y'?true:false"
                  :prop="item.Prop"
                  :fixed="item.IsFixed==='Y'?true:false"
                  :label="item.Label"
                  :show-overflow-tooltip="item.Overflow_Tooltip==='Y'?true:false"
                  :align="item.Align?item.Align:'center'"
                  :width="item.Width?item.Width:''"
                  :formatter="item.Formatter">
                 <template v-if="item.IsImage"  v-slot:default="scope">
                    <img v-if="scope.row[item.Prop]" :src="scope.row[item.Prop]" alt="" style="width: 20px; height: 20px;">                
                 </template>
              </el-table-column>              
          </template>
          <slot name="table_oper"/>    
      </el-table>
      <el-pagination class="pg" v-if="tableConfig.pager.PageStatus==1"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tableConfig.pager.PageIndex"
          :page-size="tableConfig.pager.PageSize"
          :page-sizes="tableConfig.pager.PageSizes?tableConfig.pager.PageSizes:[5, 10, 50, 100]"
          :total="tableConfig.pager.TotalCount"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
  </div>
</template>

<script>
import service from '@/router/request'
import linq from 'linq';
export default {
  name: 'commonTable',
  props: {
    tableData: {
        type:Array,
        default: []
    },
    columnsData: Array,
    tableConfig:{
        refName:{
            type: String,
            default: 'FixCommonTable'
        },
        pager: Object,
        checkType:{
            type: String,
            default: ''
        },
    },      
    maxHeight: {
        type: Number,
        default: 700
    }
  },
  data(){
      return {
      
      }
  },
  mounted(){
    let self=this;
  },
  methods: {
      handleSelectionChange(val) {
          this.$emit('handleSelectionChange', val);
      },
      handleSizeChange(val) {
          this.$emit('handleSizeChange', val);
      },
      handleCurrentChange(val) {
          this.$emit('handleCurrentChange', val);
      },
    //   formatterValue(row, column, cellValue) {
    //     console.log(row, '-row')
    //     console.log(column, '-column')
    //     console.log(cellValue, '-cellValue')
    //     return cellValue
    //       //console.log(111)
    //       //return cellValue;
    //     //   let self=this;
    //     //     let EndValue = ''
    //     //     var columnInfo=linq.from(self.columnsData).firstOrDefault(n=>n.Prop == column.property);
    //     //     if(columnInfo && columnInfo.Formatter){
    //     //         var para={...row};
    //     //         para.Prop = columnInfo.Prop;
    //     //         //self.$parent
    //     //             self.$emit(columnInfo.Formatter,para);
                           
    //     //     }
    //     //     else{
    //     //         EndValue = cellValue;
    //     //     }
    //     //     return EndValue;
    //   }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  .el-pagination {
      text-align: right;
      margin:20px 0;
  }
  .pg {
      margin: 5px;
      text-align: right;
  }
  .tableClass{
      height: 100%;
      width:100%;
  }
  .tablePagerClass{
      height: calc(100% - 38px);
      width:100%;
  }
</style>