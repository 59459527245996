<template>
  <div id="app">
    <router-view /> 
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
body,html{
		height: 100%;
    margin:0px;
	}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
</style>