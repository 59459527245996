import Vue from 'vue';
import App from './App.vue'
import axios from 'axios'
import qs from 'qs'
import VueAxios from 'vue-axios'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import components from '@/components/index.js'; //引入通用组件
import dialogDragDerective from '@/components/common/dialogDragDerective.js'//自定义组件
import scrollDerective from '@/components/common/scrollDerective.js'//自定义组件
import {dateUtils} from '@/utils/dateUtils.js'
import {funDataUtils} from '@/utils/funDataUtils.js'
import moment from 'moment'
 
//全局过滤器
Vue.filter('dateFmt', (input, formatString = "YYYY-MM-DD") => {
  //es5函数参数设置默认值
  //const lastFormatString = formatString || ''

   // moment(input) 把时间字符串转成时间对象
   // format(formatString) 把时间对象，按照指定格式，格式化成符合条件的字符串
  return moment(input).format(formatString)
})

Vue.config.productionTip = false
Vue.prototype.qs = qs
//Axios引入
Vue.use(VueAxios, axios);
//引入时间戳格式化
//Vue.use(dateUtils);
Vue.prototype.dateUtils = dateUtils
Vue.prototype.funDataUtils = funDataUtils
//router路由引入
Vue.use(router);
// ElementUI全局注入到Vue项目中
// 修改 el-dialog 默认点击遮照不关闭
ElementUI.Dialog.props.closeOnClickModal.default = false;

Vue.use(ElementUI, { size: 'small', zIndex: 3000 })
//引入通用组件
Vue.use(components);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
