<template>
    <div style="width:100%;height: 45px;">
        <el-form v-if="$slots.target" :label-width="labelWidth" :class="formClass" :inline="true" class="demo-form-inline">
            <el-form-item>
                <slot name="target"></slot><!-- 查询Form条件 -->               
            </el-form-item>
        </el-form>          
        <el-row ref="actions" v-if="$slots.actions" class="search-table__action" :class="actionsClassName">
                <slot name="actions"></slot><!-- 操作按钮 -->
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'SearchForm',
        data() {
            return {
                actionsClassName:'actionsClass_30',
            }
        },
        props: {
            labelWidth: {
                type: String,
                default: '70px'
            },
            formClass:{
                type: String,
                default: 'formClass_60'
            }
        },
        mounted(){
            let self=this;
            if(self.formClass ==='formClass_100'){
                self.actionsClassName='actionsClass_1'
            }
            else if(self.formClass === 'formClass_80') {
                self.actionsClassName='actionsClass_10'
            }
            else if(self.formClass ==='formClass_60') {
                self.actionsClassName='actionsClass_30'            
            }
            else if(self.formClass ==='formClass_50') {
                self.actionsClassName='actionsClass_40'            
            }
        },
        methods: {
        }
    }
</script>
<style scoped>
.el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
    margin-bottom: 0px;
}
.formClass_50{
    width:50%;
    float: left;    
}
.actionsClass_40 {
    width:40%;
    text-align: right;
    float: right;
}

.formClass_60{
    width:60%;
    float: left;    
}

.actionsClass_30 {
    width:30%;
    text-align: right;
    float: right;
}

.formClass_80{
    width:80%;
    float: left;    
}

.actionsClass_10 {
    width:10%;
    text-align: right;
    float: right;
}

.formClass_100{
    width:100%;
    float: left;    
}

.actionsClass_1{
    width:1%;
    text-align: right;
    float: right;
}
</style>