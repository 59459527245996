import Vue from 'vue'
 
Vue.directive('scroll', {
    bind: function (el, binding, vnode) {
        const pause = (e) => {
            let auto = binding.value ? binding.value.auto : false
            let scrolled = el.scrollTop + el.clientHeight + 1 < el.scrollHeight
            let timeout
            window.clearTimeout(timeout)
            timeout = setTimeout(() => {
              if (auto && scrolled) {
                el.setAttribute('v-scroll2-manually', 'yes')
              } else if (auto && !scrolled) {
                el.setAttribute('v-scroll2-manually', '')
              } else {
                el.removeEventListener('scroll', pause)
              }
            }, 600)
          }
          el.addEventListener('scroll', pause)
    },
    update (el, binding, vnode) {
        if (!el.getAttribute('v-scroll2-manually')) {
          vnode.context.$nextTick(() => {
            el.scrollTop = el.scrollHeight - el.clientHeight
          })
        }
    }
});