import commonTable from '../components/common/commonTable.vue'
import fixedColumnsTable from '../components/common/fixedColumnsTable.vue'
import fixedSearchForm from '../components/common/searchForm.vue'
import selectTreeMultiple from '../components/common/selectTreeMultiple.vue'
import selectTreeRadio from '../components/common/selectTreeRadio.vue'

export default {
  install(Vue) {
    Vue.component('commonTable', commonTable);
    Vue.component('fixedColumnsTable', fixedColumnsTable);
    Vue.component('search-form', fixedSearchForm);
    Vue.component('select-tree-multiple', selectTreeMultiple);
    Vue.component('select-tree-radio', selectTreeRadio);
  }
}