import service from '@/router/request'

const funDataUtils = {
    GetPopDownDataByType(typeName){
        service({url: '/service/Hr/SystemApp/GetPopDownDataByType',method: 'get', params: {Key:typeName}})
                    .then(resp => {
                        return resp.Data;
                    })
                    .catch(error => {
                        console.log(error)
                    });
                }
            }

export {funDataUtils}